<div #content class="w-100" [ngClass]="class">
	<ng-content> </ng-content>
</div>
<ng-container *ngIf="fc.dirty && fc.invalid">
	<ng-container *ngIf="fc?.hasError('serverError')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert"> {{ fc.getError('serverError')[0] }} </span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('required')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert">{{ 'VALIDATION.REQUIRED' | translate: { field: field } }}</span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('email')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert">
					{{ 'VALIDATION.EMAIL' | translate: { field: field } }}
				</span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('isNumber')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert">
					{{ 'VALIDATION.NUMBER' | translate: { field: field } }}
				</span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('integer')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert"> This field requried a valid integer </span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('minCheck')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert">
					{{ 'VALIDATION.MINCHECK' | translate: { field: field, value: fc?.getError('minCheck')?.required } }}
				</span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('min')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert">{{
					'VALIDATION.MIN' | translate: { field: field, value: fc.errors?.min.min }
				}}</span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('max')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert">{{
					'VALIDATION.MAX' | translate: { field: field, value: fc.errors?.max.max }
				}}</span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('minlength')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert">
					{{
						'VALIDATION.MINLENGTH' | translate: { field: field, value: fc.errors?.minlength.requiredLength }
					}}
				</span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('maxlength')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert"
					>{{
						'VALIDATION.MAXLENGTH' | translate: { field: field, value: fc.errors?.maxlength.requiredLength }
					}}
				</span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('url')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert">{{ 'VALIDATION.URL' | translate: { field: field } }} </span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('alphanumeric')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert">{{ 'VALIDATION.ALPHANUMERIC' | translate: { field: field } }} </span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('mismatch')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert"
					>{{ 'VALIDATION.MISMATCH' | translate: { field: field, target: fc.errors?.target } }}
				</span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('noScriptTag')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert">{{ 'VALIDATION.NOSCRIPTTAG' | translate: { field: field } }} </span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('atLeast')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert"
					>{{ 'VALIDATION.ATLEAST' | translate: { field: field, value: fc.errors.value } }}
				</span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="fc?.hasError('price')">
		<div class="fv-plugins-message-container">
			<div class="fv-help-block">
				<span role="alert"> Invalid price format </span>
			</div>
		</div>
	</ng-container>
</ng-container>
