<div class="mb-5 hover-scroll-x">
	<ng-container *ngIf="tabable">
		<div class="ivu-tabs ivu-tabs-card">
			<div class="ivu-tabs-bar">
				<div class="ivu-tabs-nav-container">
					<div class="ivu-tabs-nav">
						<div
							(click)="selectTab(tab.key); $event.stopPropagation()"
							*ngFor="let tab of tabs; let i = index"
							class="ivu-tabs-tab"
							[ngClass]="{
								'ivu-tabs-tab-active': selectedTab === tab.key,
								'ivu-tabs-tab-hovered': hoveredTab === tab.key
							}"
							(mouseenter)="toggle(tab.key, true)"
							(mouseleave)="toggle(tab.key, false)">
							<div
								class="d-flex flex-stack"
								[ngClass]="{
									'gap-4': tab.key !== 'all' && (selectedTab === tab.key || hoveredTab === tab.key)
								}">
								<input
									#editInput
									type="text"
									class="w-100"
									*ngIf="tab.editable == true"
									[(ngModel)]="tab.name"
									(clickOutside)="editClickOutside(tab.key)" />
								<div class="text-trancate-1" *ngIf="tab.editable == false">
									{{ tab.name }}
								</div>
								<div
									class="d-flex align-items-center gap-3"
									*ngIf="hoveredTab === tab.key || selectedTab === tab.key">
									<i
										class="bi bi-pencil-square text-gray-800"
										(click)="edit($event, i)"
										clickOutside
										*ngIf="tab.key !== 'all'"></i>
									<i
										*ngIf="!tab.isSaved && tab.key !== 'all'"
										class="bi bi-star fs-4 text-gray-800"
										(click)="star($event, tab.key, true)"></i>
									<i
										*ngIf="tab.isSaved && tab.key !== 'all'"
										class="bi bi-star-fill fs-4 text-warning"
										(click)="star($event, tab.key, false)"></i>

									<i
										class="bi bi-x-lg fs-4 text-gray-800"
										(click)="close($event, tab.key)"
										*ngIf="tab.key !== 'all'"></i>
								</div>
							</div>
						</div>
						<div class="ivu-tabs-tab offline" (click)="addTab()">
							<div class="d-flex align-items-center justify-content-center">
								<span class="fs-5">+</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<div
		[ngClass]="{
			'isLoading overlay overlay-block': isLoading,
			'min-h-300px': data == null || (isArray() && !data?.length)
		}"
		class="pt-2">
		<div
			class="overlay-layer card-rounded d-flex flex-column"
			[ngClass]="{ borderRadiusCurve: borderRadiusCurve }"
			*ngIf="isLoading">
			<div class="spinner-border text-black" role="status"></div>
		</div>
		<ng-container *ngIf="isArray() && !data?.length">
			<div class="d-flex align-items-center justify-content-center min-h-300px w-100 flex-column">
				<span class="bi bi-search no-found text-gray-800"></span>
				<div class="mt-2 text-center">
					<span class="fs-1 fw-semibold text-gray-800">Data not found.</span>
				</div>
				<span class="text-muted fw-semibold">Please try change the filter or search terms</span>
			</div>
		</ng-container>
		<ng-container *ngIf="isArray() && data?.length">
			<div
				class="table-responsive table-custom"
				[ngClass]="{ 'no-hover': noHover }"
				[style.min-height]="noMinHeight ? 'none' : '250px'">
				<table class="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
					<ng-content select="[header]" *ngIf="flexible; else inflexible"> </ng-content>
					<ng-template #inflexible>
						<thead class="border-gray-200 fw-semibold text-gray-600 fs-6">
							<tr>
								<th
									ngClass="{{ header.class }}"
									*ngFor="let header of headers; let i = index"
									class="cursor-pointer"
									(click)="updateSorting(i)">
									<div class="d-flex align-items-center">
										<span>{{ header.text }}</span>
										<span class="bi bi-arrow-up ms-2" *ngIf="header.sort == 'asc'"> </span>
										<span class="bi bi-arrow-down ms-2" *ngIf="header.sort == 'desc'"> </span>
									</div>
								</th>
							</tr>
						</thead>
					</ng-template>
					<ng-content select="[body]"></ng-content>
				</table>
			</div>
		</ng-container>
	</div>
	<ng-container *ngIf="isArray() && data?.length">
		<div class="d-flex flex-column flex-lg-row gap-4 justify-content-between m-4 align-items-center">
			<div class="ms-2">
				@if (!noPaginate) {
					<select
						*ngIf="!noPageSize"
						class="form-select form-select-sm form-select-solid"
						[(ngModel)]="pageSize"
						(ngModelChange)="updatePageSize($event.target)">
						<option value="5">5</option>
						<option value="10" selected>10</option>
						<option value="20">20</option>
						<option value="50">50</option>
					</select>
				}
			</div>
			<ngb-pagination
				[collectionSize]="totalData"
				[(page)]="page"
				[pageSize]="pageSize"
				[rotate]="true"
				[ellipses]="false"
				[boundaryLinks]="true"
				[maxSize]="5"
				(pageChange)="updatePage()">
			</ngb-pagination>
			<span class="fs5 fw-semibold text-gray-800 pe-4" *ngIf="from && to">
				Showing {{ from }} to {{ to }} of {{ totalData }} results
			</span>
		</div>
	</ng-container>
</div>
